var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('div',{staticClass:"mb-6"},[_c('h2',{staticClass:"text-2xl font-bold text-center"},[_vm._v(" "+_vm._s(_vm.$store.state.locale == "ru" ? "Оставьте отзыв" : "Leave a comment")+" ")]),_c('p',{staticClass:"mt-2 font-medium text-center text-gray-500"},[_vm._v(" "+_vm._s(_vm.$store.state.locale == "ru" ? ("Филиал \"" + (_vm.branches[0].title_ru) + "\"") : ("Branch \"" + (_vm.branches[0].title_en) + "\""))+" ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700 text-sm mb-1 font-medium",attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$store.state.locale == "ru" ? "Имя" : "Name")+" "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"w-full py-2.5 px-2 border-2 rounded-lg",attrs:{"id":"name","name":"name","type":"text","placeholder":"Азиз Олимов"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"rules":"required|length:19"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700 text-sm font-medium",attrs:{"for":"phone"}},[_vm._v(" "+_vm._s(_vm.$store.state.locale == "ru" ? "Номер телефона" : "Phone number")+" "),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])]),_c('div',{staticClass:"mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"},{name:"mask",rawName:"v-mask",value:('+(###) ## ###-##-##'),expression:"'+(###) ## ###-##-##'"}],staticClass:"w-full py-2.5 px-2 border-2 rounded-lg",attrs:{"id":"phone","name":"phone","type":"phone"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{staticClass:"mt-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-700 text-sm font-medium",attrs:{"for":"message"}},[_vm._v(" "+_vm._s(_vm.$store.state.locale == "ru" ? "Сообщение" : "Message")+" ")]),_c('div',{staticClass:"mt-1"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"w-full py-2.5 px-2 border-2 rounded-lg",attrs:{"id":"message","placeholder":("" + (_vm.$store.state.locale == 'ru'
                    ? 'Пишите свои пожелания, предложения или жалобу...'
                    : 'Write your wishes, suggestions or complaint')),"name":"message","rows":"4"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._l((_vm.gradings),function(grading){return _c('div',{key:grading.id},[_c('label',{staticClass:"block mt-4 text-gray-700 text-sm font-medium"},[_vm._v(" "+_vm._s(_vm.$store.state.locale == "ru" ? grading.title_ru : grading.title_en)+" ")]),_c('div',{staticClass:"flex justify-center p-4"},_vm._l((_vm.reactions),function(reaction){return _c('div',{key:reaction.value},[_c('button',{staticClass:"p-4 m-3",attrs:{"type":"button","name":reaction.text},on:{"click":function($event){grading.grade = reaction.value}}},[_c('div',{staticClass:"text-5xl icon-emoji hover:opacity-100",class:grading.grade !== reaction.value && 'opacity-30',domProps:{"textContent":_vm._s(reaction.emoji)}})])])}),0)])}),_c('div',{staticClass:"flex justify-center mb-4 overflow-auto"},[_c('button',{staticClass:"\n              my-4\n              py-2.5\n              w-full\n              font-medium\n              rounded-lg\n              bg-primary\n              text-white\n              flex\n              justify-center\n              mx-auto\n              disabled:opacity-75\n            ",class:{ 'opacity-60': invalid },attrs:{"type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$store.state.locale == "ru" ? "Подтвердить" : "Confirm")+" "),(_vm.loading)?_c('svg',{staticClass:"animate-spin w-6 h-6 text-white",staticStyle:{"fill":"rgba(255, 255, 255, 1)","transform":"","msfilter":""},attrs:{"xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"}})]):_vm._e()])])],2)]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }